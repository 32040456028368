
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3210'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3412'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled
                        }, this.useNativeDropdown ? [
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n          ', window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n        ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7751'
                                }, 'Popular Makes'),
                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7754'
                                }, 'All Makes'),
                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                            ] : null,
                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                            'key': '_current',
                            'value': '_current'
                        }, '\n        ', this.selectedEntries.length ? this.selectedEntries[0].value : window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'key': '2580'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      ', window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '80'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.themeSettings.locales.ymm.select_block), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button btn--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    ', window.themeSettings.locales.ymm.go, '\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button btn--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'cm_icon cm_icon-reset',
                    'height': '16px',
                    'viewBox': '0 0 16 16',
                    'key': '64290'
                }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '6950'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button button btn--primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      ', window.themeSettings.locales.ymm.change, '\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button button btn--secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      ', window.themeSettings.locales.ymm.clear, '\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarWheels(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3320'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3522'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8851'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8854'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? this.selectedEntries[0].value : window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'key': '2690'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = window.themeSettings.locales.facet_bar_wheels;
                return _createElement('div', { 'className': 'cm_FacetBarWheels' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button btn--secondary',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection,
                    'role': 'button',
                    'tabIndex': this.hasSelection ? '0' : '-1'
                }, [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'cm_icon cm_icon-reset',
                        'height': '16px',
                        'viewBox': '0 0 16 16',
                        'key': '64780'
                    }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FacetBarTires(function () {
            function repeatSelects1(facet_bar_label, selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3320'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3522'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'cmTemplate_selectFacet' }, _createElement('div', { 'className': 'cm_vehicle-widget_select' }, [_createElement('div', {
                                'className': this.extraClassName,
                                'key': '980'
                            }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled
                            }, this.useNativeDropdown ? [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8851'
                                    }, 'Popular Makes'),
                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '8854'
                                    }, 'All Makes'),
                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                ] : null,
                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, '\n        ', this.selectedEntries.length ? this.selectedEntries[0].value : window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'key': '2690'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      ', window.themeSettings.locales.ymm[this.title.toLowerCase()] || this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                            ]) : null)]));
                    }, { count: undefined })];
            }
            function scopeFacet_bar_label2() {
                var facet_bar_label = window.themeSettings.locales.facet_bar_tires;
                return _createElement('div', { 'className': 'cm_FacetBarTires' }, _createElement('div', { 'className': 'cm_FacetBarWheelsTires cm_facets' }, _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__facet-bar' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n        ', facet_bar_label, '\n      '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cm_vehicle-widget_select-container cmRepeater_facets cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this, facet_bar_label))
                ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                    'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button btn--secondary',
                    'onClick': this.discardFields,
                    'disabled': !this.hasSelection,
                    'role': 'button',
                    'tabIndex': this.hasSelection ? '0' : '-1'
                }, [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'className': 'cm_icon cm_icon-reset',
                        'height': '16px',
                        'viewBox': '0 0 16 16',
                        'key': '64760'
                    }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))))));
            }
            return scopeFacet_bar_label2.apply(this, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button btn--primary cm_dialogButton' }, '\n  ', window.themeSettings.locales.mobile_filter_button, ' \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '1090'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8500'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' ', window.themeSettings.locales.total_results)), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, window.themeSettings.locales.sort_select), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'col-md-4 col-sm-6 col-xs-6' }, _createElement('div', { 'className': 'grid-product-item product-item style-01 rows-space-40' + (window.Convermax.config.payBrightToken ? ' cm_paybright' : '') }, _createElement('div', { 'className': 'product-inner' }, _createElement('div', { 'className': 'product-thumb' }, _createElement('a', {
                            'className': 'thumb-link' + (this.image2 ? ' first-image' : ''),
                            'href': this.url
                        }, _createElement('img', {
                            'className': 'img-responsive',
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError
                        })), this.image2 ? _createElement('div', {
                            'className': 'second-image',
                            'key': '549'
                        }, _createElement('a', {
                            'href': this.url,
                            'className': 'thumb-link'
                        }, _createElement('img', {
                            'className': 'img-responsive',
                            'src': this.imageOrDefault(this.resizeImage(this.image2)),
                            'alt': this.removeHTML(this.title),
                            'onError': e => this.onImageError(e, 'image2')
                        }))) : null, _createElement('div', { 'className': 'flash' }, !this.out_of_stock && this.on_sale ? _createElement('span', {
                            'className': 'onsale',
                            'key': '914'
                        }, _createElement('span', {}, '-', Math.floor((1 - this.price / this.compare_at_price) * 100), '%')) : null, this.out_of_stock ? _createElement('span', {
                            'className': 'onsoldout',
                            'key': '1103'
                        }, _createElement('span', {}, window.themeSettings.locales.sold_out)) : null, !this.out_of_stock && this.stock && (this.stock?.includes('Available') && !this.stock?.includes('In-stock Only')) ? _createElement('span', {
                            'className': 'onspecial',
                            'key': '1247'
                        }, _createElement('span', {}, window.themeSettings.locales.special_order)) : null, window.Convermax.productIsNew(this.published_at) ? _createElement('span', {
                            'className': 'onnew',
                            'key': '1536'
                        }, _createElement('span', {}, window.themeSettings.locales.new)) : null), _createElement('div', { 'className': 'group-button' }, _createElement('a', {
                            'data-id': this.handle,
                            'className': 'engoj_btn_quickview button engoc-btn-quickview'
                        }, _createElement('i', { 'className': 'flaticon-magnifying-glass-browser fz-21' })), _createElement('div', {
                            'className': 'engoc-add-to-wishlist',
                            'data-icon-wishlist': true,
                            'data-product-handle': this.handle
                        }, _createElement('a', { 'className': 'add_to_wishlist' }, _createElement('i', { 'className': 'flaticon-heart-shape-outline' }))))), _createElement('div', { 'className': 'product-info equal-elem' }, _createElement('div', { 'className': 'cat-list' }, _createElement('a', Object.assign({}, {
                            'href': this.vendor_url,
                            'title': this.removeHTML(this.vendor)
                        }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h3', { 'className': 'product-name product_title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-info-inner' }, _createElement('span', { 'className': 'price' }, this.price_varies ? _createElement('span', { 'key': '2650' }, 'From: ') : null, this.on_sale ? _createElement('span', {
                            'className': 'money del',
                            'key': '2708'
                        }, this.formatPrice(this.compare_at_price)) : null, this.on_sale ? _createElement('span', {
                            'className': 'money ins',
                            'key': '2814'
                        }, this.formatPrice(this.price)) : null, !this.on_sale ? _createElement('span', {
                            'className': 'money',
                            'key': '2909'
                        }, this.formatPrice(this.price)) : null), !this.out_of_stock ? _createElement('div', {
                            'className': 'add-to-cart',
                            'key': '3018'
                        }, _createElement('div', { 'className': 'add-to-cart-wrap azirspares-add-to-cart-wrap' }, this.variant_ids.length === 1 ? _createElement('form', {
                            'method': 'post',
                            'action': '/cart/add',
                            'key': '3156'
                        }, _createElement('input', {
                            'type': 'hidden',
                            'name': 'id',
                            'value': this.variant_ids
                        }), _createElement('button', {
                            'type': 'submit',
                            'name': 'add',
                            'id': 'AddToCart' + this.id,
                            'className': 'enj-add-to-cart-btn engoc-btn-default'
                        }, _createElement('i', { 'className': 'flaticon-online-shopping-cart' }))) : null, this.variant_ids.length > 1 ? _createElement('a', {
                            'href': this.url,
                            'key': '3554'
                        }, _createElement('i', { 'className': 'flaticon-online-shopping-cart' })) : null)) : null), this.price ? _createElement('p', {
                            'className': 'affirm-as-low-as',
                            'data-amount': this.price * 100,
                            'data-affirm-type': 'logo',
                            'data-affirm-color': 'blue',
                            'key': '3752'
                        }) : null))));
                    }, { count: undefined })];
            }
            return window.Convermax.showConvermaxResults ? _createElement('div', {
                'className': 'productGrid--maxCol3 cm_SearchResult',
                'key': '0'
            }, _createElement('div', { 'className': 'content-box equal-container better-height' }, _createElement.apply(this, [
                'div',
                { 'className': 'engoc-row-equal-20 grid-uniform cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ])), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t contain any\n    ',
                        !!this.vehicleString && !this.notFilteredByVehicle ? [
                            '\n      products that fit a ',
                            _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                        '\n    Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '32301'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3749'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3968'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4212'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '43630' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '44500' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '45330' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '43284' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse button btn--primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, _createElement('span', {}, window.themeSettings.locales.load_more));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]) : null;
        }, {
            widgetName: 'results',
            items: undefined
        })]);
}
        export const componentNames = ["cm:message","cm:facetToggle","cm:SearchVehicleWidget","cm:FacetBarWheels","cm:FacetBarTires","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]